import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import HomePage from "./home";
import DocsPage from './docs';
import EthicsPage from "./ethics";
import MyProjectsPage from "./myProjects";



const Webpages = () => {
    return (
        <Router>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/docs" component={DocsPage} />
            <Route exact path="/ethics" component={EthicsPage} />
            <Route exact path="/my-projects" component={MyProjectsPage} />
        </Router>
    );
};

export default Webpages;
