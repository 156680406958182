import * as React from "react";
import { Container } from "@mui/material";
import { Grid } from "@mui/material";
import Header from "../components/header";


// markup
function DocsPage() {

  return (
    <main>
      <div>
        <Container maxWidth="lg">
          <Header />

          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <h4>Docs</h4>
              <p>
                <a href="https://jagadeesh32.github.io/green-book/" target="_blank" rel="noreferrer">
                  The Green Book
                </a>
              </p>
              <p>
                <a href="https://jagadeesh32.github.io/book-of-cloud/" target="_blank" rel="noreferrer">
                  The Book of Cloud
                </a>
              </p>
              <p>
                <a href="https://jagadeesh32.github.io/developer-life/" target="_blank" rel="noreferrer">
                  A Developer Life
                </a>
              </p>
              <p>
                <a href="https://jagadeesh32.github.io/go-with-green/" target="_blank" rel="noreferrer">
                  Go with green
                </a>
              </p>
              {/* 
              <p>
                <a href="https://jagadeesh32.github.io//">
                  Go with green
                </a>
              </p>
              */}
            </Grid>
          </Grid>
        </Container>
      </div>
    </main>
  );
}

export default DocsPage;
