import * as React from "react";
import { Container } from "@mui/material";
import { Grid } from "@mui/material";

import Header from "../components/header";
import '../styles/home.css';


// markup
function HomePage() {

  return (
    <main>
      <div>
        <Container maxWidth="lg">
          <Header />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <h2>open source work</h2>
              <p>
                <a
                  href="https://github.com/jagadeesh32?tab=repositories&q=&type=public&language=&sort="
                  target="_blank" rel="noreferrer"
                >
                  projects
                </a>
              </p>
              <p>
                <a href="/docs">
                  docs
                </a>
              </p>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <h2>personal blog</h2>
              <p>
                <a
                  href="//blog.jagadeeshkatla.com/articles"
                  target="_blank" rel="noreferrer"
                >
                  articles
                </a>
              </p>
              <p>
                <a
                  href="//blog.jagadeeshkatla.com/snippets"
                  target="_blank" rel="noreferrer"
                >
                  snippets
                </a>
              </p>
              <p>
                <a
                  href="//blog.jagadeeshkatla.com/tutorials"
                  target="_blank" rel="noreferrer"
                >
                  tutorials
                </a>
              </p>
              <p>
                <a
                  href="//blog.jagadeeshkatla.com/series"
                  target="_blank" rel="noreferrer"
                >
                  series
                </a>
              </p>
              {/*
              <p>
                <a
                  href="https://www.youtube.com/channel/UCQAUMPZf_48sI2pFNd3Z-nw"
                  target="_blank" rel="noreferrer"
                  >
                vlog
                </a>
              </p>
              */}
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <h2>professional career</h2>
              <p>
                <a href="/ethics">
                  ethics
                </a>
              </p>
              {/*
              <p>
                <a href="/my-projects">
                projects (active & completed)
                </a>
              </p>
              */}
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <h2>monthly logs</h2>
              <p>
                <a href="https://jagadeesh32.github.io/green-book/"
                  target="_blank" rel="noreferrer">
                  working on green book
                </a>
              </p>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <h2>contact</h2>
              <p className="para_label">
                email &nbsp; &nbsp; &nbsp;{" "}
                <a
                  href="mailto:william@yossarian.net"
                  target="_blank" rel="noreferrer"
                >
                  katla.jagadeesh@gmail.com
                </a>
              </p>
              <p className="para_label">
                keybase &nbsp;
                <a
                  href="https://keybase.io/jagadeesh32"
                  target="_blank" rel="noreferrer"
                >
                  &nbsp;jagadeesh32
                </a>
              </p>
              <p className="para_label">
                github &nbsp; &nbsp;{" "}
                <a
                  href="https://github.com/jagadeesh32"
                  target="_blank" rel="noreferrer"
                >
                  jagadeesh32
                </a>
              </p>
              <p className="para_label">
                twitter &nbsp; &nbsp;{" "}
                <a
                  href="https://twitter.com/jagadeesh_katla"
                  target="_blank" rel="noreferrer"
                >
                  jagadeesh katla
                </a>
              </p>
              <p className="para_label">
                linkedin &nbsp;
                <a
                  href="https://www.linkedin.com/in/jagadeesh-katla"
                  target="_blank" rel="noreferrer"
                >
                  jagadeesh katla
                </a>
              </p>
            </Grid>
          </Grid>
        </Container>
      </div>

    </main>

  );
}

export default HomePage;
