import * as React from "react";
import { Grid } from "@mui/material";

import '../styles/header.css';


function Header() {
  return (
    <Grid item xs={12} sm={12}>
      
      <h1 className="heading_one">
        the personal website of jagadeesh katla.
        <br />
        <span className="heading_two">
          "— and me? love software technologies better then human history."{" "}
        </span>
        <span role="img" aria-label="Party popper emojis">
          {/* 🎉🎉🎉👨‍💻👨‍💻👨‍💻 */}
        </span>
      </h1>
    </Grid>
  );
}

export default Header;