import * as React from "react";
import { Container } from "@mui/material";

import Header from "../components/header";


// markup
function MyProjectsPage() {

    return (
        <main>
            <div>
                <Container maxWidth="lg">
                    <Header />
                    <h2>projects</h2>

                    <p>
                        <a href="https://hdbfs.com/" target="_blank" rel="noreferrer">
                            HDB financial services (Active)
                        </a>
                    </p>
                    <p>
                        <a href="https://dma.vrindasoft.net/" target="_blank" rel="noreferrer">
                            DMA Advocates (Active)
                        </a>
                    </p>
                    <p>
                        <a href="https://infared.co/" target="_blank" rel="noreferrer">
                            Infared
                        </a>
                    </p>
                    <p>
                        <a href="http://oncoplus.qa.oi.co.uk/" target="_blank" rel="noreferrer">
                            Oncoplus
                        </a>
                    </p>
                    <p>
                        <a href="https://anvilegal.com/" target="_blank" rel="noreferrer">
                            Anvi legal
                        </a>
                    </p>
                    <p>
                        <a href="https://medigin.in/" target="_blank" rel="noreferrer">
                            Medigin
                        </a>
                    </p>
                    <p>
                        <a href="https://aexpress.co.in/" target="_blank" rel="noreferrer">
                            Aexpress
                        </a>
                    </p>
                    <p>
                        <a href="https://www.mylibribooks.com/" target="_blank" rel="noreferrer">
                            Libri
                        </a>
                    </p>
                    <p>
                        <a href="https://writersoutlet.io/" target="_blank" rel="noreferrer">
                            Writers Outlet
                        </a>
                    </p>
                </Container>
            </div>
        </main>
    );
}

export default MyProjectsPage;
