import './App.css';
import Webpages from './pages';


function App() {
  return (
    <div>
      <Webpages />
    </div>
  );
}
export default App;