import * as React from "react";
import { Container } from "@mui/material";
import { Grid } from "@mui/material";

import Header from "../components/header";


// markup
function EthicsPage() {

  return (
    <main>
      <div>
        <Container maxWidth="lg">
          
          <Header />
          <h2>ethics</h2>

          <Grid container xs={12} sm={12} md={12} lg={12}>
            <ul>
              <li>
                Contribute to society and human well-being.
              </li>
              <li>
                Avoid harm to others.
              </li>
              <li>
                Be honest and trustworthy.
              </li>
              <li>
                Give proper credit for intellectual property.
              </li>
              <li>
                Respect the privacy of others.
              </li>
              <li>
                Honor confidentiality.
              </li>
              <li>
                Approve software only if they have a well-founded belief it is
                safe and meets specifications.
              </li>
              <li>
                Accept full responsibility for their own work.
              </li>
              <li>
                Not knowingly use software that is obtained or retained either
                illegally or unethically.
              </li>
              <li>
                Identify, define, and address ethical, economic, cultural,
                legal and environmental issues related to work projects.
              </li>
              <li>
                Ensure that specifications for software on which they work
                satisfy the users’ requirements and they have the appropriate
                approvals.
              </li>
              <li>
                Ensure adequate testing, debugging and review of software.
              </li>
              <li>
                Not engage in deceptive financial practices such as bribery,
                double billing, or other improper financial practices.
              </li>
              <li>
                Improve their ability to create safe, reliable, and useful
                quality software.
              </li>
            </ul>
          </Grid>
        </Container>
      </div>
    </main>
  );
}

export default EthicsPage;
